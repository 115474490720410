import {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react"
import Link from "next/link"
import { useTheme } from "next-themes"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
  BodyScrollOptions,
} from "body-scroll-lock"
import cn from "classnames"
import ReactGA from "react-ga4"

import BurgerMenu from "../BurgerMenu/BurgerMenu"
import { SettingsContext } from "@/context/settings"
import { IMenuItem } from "@/types/contentful"

import LogotypeDark from "@/assets/logo_dark.svg"
import LogotypeLight from "@/assets/logo_light.svg"

import styles from "./styles.module.scss"

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
}

interface HeaderProps {
  menu: IMenuItem[]
  isLogoScrolled?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({
  menu,
  isLogoScrolled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(isLogoScrolled)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isPageLoad, setIsPageLoad] = useState(false)
  const [dropdownActive, setDropdownActive] = useState(false)
  const [dropdownFocused, setDropdownFocused] = useState(false)

  const { resolvedTheme } = useTheme()
  const navigationRef = useRef(null)
  const settingsContext = useContext(SettingsContext)

  const handleClick = () => {
    if (navigationRef.current) {
      if (isOpen) {
        enableBodyScroll(navigationRef.current)
        document.body.classList.remove("blocked")
        document.documentElement.classList.remove("blocked")
      } else {
        disableBodyScroll(navigationRef.current, options)
        document.body.classList.add("blocked")
        document.documentElement.classList.add("blocked")
      }
    }
    setIsOpen(!isOpen)
  }

  const handleLinkClick = () => {
    clearAllBodyScrollLocks()
    ReactGA.event("need_help_button")
    setIsOpen(false)
    document.body.classList.remove("blocked")
    document.documentElement.classList.remove("blocked")
  }

  const handleScroll = useCallback(() => {
    if (isLogoScrolled) {
      if (window.scrollY >= 20) {
        setIsScrolling(true)
      } else {
        setIsScrolling(false)
      }
    }
  }, [isLogoScrolled])

  const handleDropdown = (e: any) => {
    switch (e.type) {
      case "click":
        if (dropdownFocused && dropdownActive) {
          setDropdownFocused(false)
        }
        handleLinkClick()
        break
      case "mouseenter":
        setDropdownActive(true)
        break
      case "mouseleave":
        setDropdownActive(false)
        break
      case "keyup":
        setDropdownFocused(true)
        break
    }
  }

  const handleClickPhone = () => {
    ReactGA.event("phone_number", {
      position: "header",
    })
  }

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  useEffect(() => {
    if (isLogoScrolled) {
      window.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (isLogoScrolled) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [isLogoScrolled, handleScroll])

  useEffect(() => {
    setIsScrolled(isLogoScrolled)
    handleScroll()
  }, [isLogoScrolled, handleScroll])

  useEffect(() => {
    if (!isScrolling) {
      setTimeout(() => {
        setIsPageLoad(true)
      }, 100)
    }
  }, [isScrolling, isLogoScrolled])

  return (
    <>
      <div className={styles.header__fake} />
      <header
        className={cn(
          styles.header,
          ((isPageLoad && isScrolling) || !isLogoScrolled) &&
            styles["header--stick"]
        )}
      >
        <div className={styles.header__container}>
          <div className={styles.header__logotype_wrapper}>
            <Link
              href="/"
              title="Home"
              onClick={handleLinkClick}
              className={cn(
                styles.header__link,
                ((isScrolled && isScrolling) || !isLogoScrolled) &&
                  styles["header__link--scroll"],
                isScrolling && styles["header__link--scrolling"],
                !isPageLoad && styles["header__link--no-scrolling"]
              )}
            >
              {resolvedTheme === "light" ? (
                <LogotypeDark className={styles.header__logotype} />
              ) : (
                <LogotypeLight className={styles.header__logotype} />
              )}
            </Link>
          </div>
          <nav
            className={cn(
              styles.header__navigation,
              isOpen && styles["header__navigation--open"],
              styles[`header__navigation--${resolvedTheme}`]
            )}
          >
            <ul className={styles.header__navigation_list} ref={navigationRef}>
              {menu.map((item: IMenuItem, index: number) => {
                return item.fields?.secondLevelMenu ? (
                  <li className={styles.nav__link} key={index}>
                    {item.fields.slug ? (
                      <Link
                        href={item.fields.slug}
                        title={item.fields.title}
                        onClick={handleDropdown}
                        onMouseEnter={handleDropdown}
                        onMouseLeave={handleDropdown}
                        onKeyUp={handleDropdown}
                      >
                        {item.fields.title}
                      </Link>
                    ) : (
                      <button
                        className={styles.nav__button}
                        onClick={handleDropdown}
                        onMouseEnter={handleDropdown}
                        onMouseLeave={handleDropdown}
                        onKeyUp={handleDropdown}
                      >
                        {item.fields.title}
                      </button>
                    )}

                    <ul
                      className={cn(
                        styles.nav__dropdown,
                        dropdownActive && styles["nav__dropdown--active"],
                        dropdownFocused && styles["nav__dropdown--focused"]
                      )}
                      onMouseEnter={handleDropdown}
                      onMouseLeave={handleDropdown}
                    >
                      {item.fields.secondLevelMenu?.map(
                        (innerItem: IMenuItem, index: number) => {
                          return (
                            innerItem.fields.slug && (
                              <li
                                className={styles.nav__dropdown_item}
                                key={index}
                              >
                                <Link
                                  className={styles.nav__dropdown_link}
                                  href={innerItem.fields.slug}
                                  title={innerItem.fields.title}
                                  onClick={handleDropdown}
                                >
                                  {innerItem.fields.title}
                                </Link>
                              </li>
                            )
                          )
                        }
                      )}
                    </ul>
                  </li>
                ) : (
                  item.fields?.slug && (
                    <li className={styles.nav__link} key={index}>
                      <Link
                        href={item.fields.slug}
                        title={item.fields.title}
                        onClick={handleLinkClick}
                      >
                        {item.fields.title}
                      </Link>
                    </li>
                  )
                )
              })}

              {settingsContext?.settings.mobilePhone && (
                <li className={styles.nav__link}>
                  <a
                    href={`tel:${settingsContext?.settings.mobilePhone[0].replace(
                      /[()\s]/g,
                      ""
                    )}`}
                    title={`Call ${settingsContext?.settings.mobilePhone[0]}`}
                    onClick={handleClickPhone}
                  >
                    {settingsContext?.settings.mobilePhone[0]}
                  </a>
                  <a
                    href="#contact"
                    className={styles.nav__hint}
                    onClick={handleLinkClick}
                  >
                    Do you need help?
                  </a>
                </li>
              )}
            </ul>
          </nav>
          <BurgerMenu
            onClick={handleClick}
            isOpen={isOpen}
            className={styles.header__menu}
          />
        </div>
      </header>
    </>
  )
}

export default Header
