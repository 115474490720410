import {
  InputHTMLAttributes,
  forwardRef,
  ForwardedRef,
  TextareaHTMLAttributes,
  createElement,
} from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

export type VariantType = "dark" | "light"
export type TagType = "input" | "textarea"

export type InputProps = {
  label?: string
  variant?: VariantType
  tag?: TagType
} & (
  | InputHTMLAttributes<HTMLInputElement>
  | TextareaHTMLAttributes<HTMLTextAreaElement>
)

const Input = forwardRef(
  (
    {
      className,
      label,
      variant = "light",
      tag = "input",
      id,
      ...props
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className={cn(styles.input, styles[`input--${variant}`], className)}>
        {label && (
          <label className={styles.input__label} htmlFor={id}>
            {label}
          </label>
        )}
        {createElement(tag, {
          ref,
          className: cn(styles.input__field, styles[`input__field--${tag}`]),
          id,
          ...props,
        })}
      </div>
    )
  }
)

Input.displayName = "Input"

export default Input
