import Link from "next/link"

import ContactUs from "../ContactUs/ContactUs"
import FooterIcons from "../FooterIcons/FooterIcons"
import Section from "../Section/Section"
import SectionTitle from "../SectionTitle/SectionTitle"

import styles from "./styles.module.scss"

interface FooterProps {}

const Footer = ({}: FooterProps) => {
  return (
    <footer className={styles.footer} id="contact">
      <Section>
        <div className={styles.footer__wrapper}>
          <div className={styles.footer__content}>
            <div className={styles.footer__header}>
              <SectionTitle variant="p" className={styles.footer__title}>
                How can we help you?
              </SectionTitle>
              <p className={styles.footer__description}>
                Feel free to contact us any time. We will get back to you as
                soon as possible.
              </p>
            </div>
            <div className={styles.footer__icons}>
              <div className={styles.footer__address}>
                <p>London Luxury Travel LTD</p>
                <p>36 Hanover Avenue, London, E16 1DX, United Kingdom</p>
              </div>
              <FooterIcons />
            </div>
            <div className={styles.footer__contact}>
              <ContactUs />
            </div>
          </div>
          <div className={styles.footer__copyright_wrapper}>
            <p className={styles.footer__copyright}>
              &copy; Copyright 2023 London Luxury Travel. All Rights Reserved
            </p>
            <ul className={styles.footer__links}>
              <li className={styles.footer__link}>
                <Link href="/terms-and-conditions">Terms</Link>
              </li>
              <li className={styles.footer__link}>
                <Link href="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className={styles.footer__link}>
                <Link href="/about-us">About Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </Section>
    </footer>
  )
}

export default Footer
