import { createRef, useCallback, useState } from "react"
import {
  useForm,
  SubmitHandler,
  FieldValues,
  Controller,
} from "react-hook-form"
import cn from "classnames"
import ReCAPTCHA from "react-google-recaptcha"
import ReactGA from "react-ga4"

import Input from "../forms/Input/Input"
import Button from "../forms/Button/Button"
import ErrorMessage from "../forms/ErrorMessage/ErrorMessage"
import PhoneInput from "../forms/Input/PhoneInput"

import styles from "./styles.module.scss"

const ContactUs = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)
  const [captchaValues, setCaptchaValues] = useState(false)
  const recaptchaRef = createRef<any>()
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isSubmitted },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      content: "",
      captcha: "",
    },
  })

  const handleFormSubmit: SubmitHandler<FieldValues> = async (values) => {
    if (!captchaValues) {
      setError("captcha", { message: "no" })
      return
    }
    ReactGA.event("form_send")
    return await fetch("/api/email", {
      method: "POST",
      body: JSON.stringify({ ...values, captcha: captchaValues }),
    })
      .then((data) => {
        if (data.status !== 200) {
          throw new Error(data.statusText)
        }
        setIsSubmitSuccessful(true)

        return data
      })
      .catch(() => {
        setIsSubmitSuccessful(false)
      })
  }
  const onReCAPTCHAChange = async (value: any) => {
    setCaptchaValues(value)
    clearErrors("captcha")
  }

  const handleResetForm = useCallback(() => {
    reset({})
    setIsSubmitSuccessful(false)
  }, [reset])

  return !isSubmitSuccessful ? (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div>
        <Controller
          control={control}
          rules={{ required: true }}
          name="name"
          render={({ field }) => (
            <Input
              type="text"
              label="Name"
              id="contact_us_name"
              variant="dark"
              {...field}
              className={cn(!errors.name && styles.form__field)}
            />
          )}
        />
        {errors.name?.type === "required" && (
          <ErrorMessage>Name is required</ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          control={control}
          rules={{ required: true }}
          name="phone"
          render={({ field }) => (
            <PhoneInput
              {...field}
              value={field.value?.toString()}
              specialLabel=""
              label="Phone number"
              variant="dark"
              id="contact_us_phone"
              className={cn(!errors.phone && styles.form__field)}
            />
          )}
        />
        {errors.phone && <ErrorMessage>Phone is required</ErrorMessage>}
      </div>
      <div>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          }}
          name="email"
          render={({ field }) => (
            <Input
              {...field}
              type="email"
              label="E-mail"
              variant="dark"
              id="contact_us_email"
              className={cn(!errors.email && styles.form__field)}
            />
          )}
        />
        {errors.email?.type === "required" && (
          <ErrorMessage>Email is required</ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          control={control}
          rules={{ required: true }}
          name="content"
          render={({ field }) => (
            <Input
              {...field}
              tag="textarea"
              label="Message"
              id="contact_us_message"
              variant="dark"
              className={cn(!errors.content && styles.form__field)}
            />
          )}
        />
        {errors.content?.type === "required" && (
          <ErrorMessage>Message shouldn&apos;t be empty</ErrorMessage>
        )}
      </div>
      <div className={styles.form__field}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lc0S18pAAAAACLQMcqtkUrVh2ljtp4ekYg1lbCa"
          onChange={onReCAPTCHAChange}
        />
        {errors.captcha && <ErrorMessage>Required</ErrorMessage>}
      </div>
      <div className={styles.form__button}>
        <Button type="submit" variant="dark" disabled={isSubmitting}>
          Send
        </Button>
      </div>
    </form>
  ) : (
    <div className={styles.form__success}>
      <h3 className={styles.form__title}>Thank you for getting in touch!</h3>
      <p className={styles.form__description}>
        We appreciate you contacting us. One of our colleagues will get back in
        touch with you soon!Have a great day!
      </p>
      <Button type="button" variant="dark" onClick={handleResetForm}>
        Send one more message
      </Button>
    </div>
  )
}

export default ContactUs
