import { useContext } from "react"
import Link from "next/link"
import ReactGA from "react-ga4"

import { SettingsContext } from "@/context/settings"
import EmailIcon from "@/assets/icons/email.svg"
import PhoneIcon from "@/assets/icons/phone.svg"
import InstagramIcon from "@/assets/icons/instagram.svg"
import FacebookIcon from "@/assets/icons/facebook.svg"

import styles from "./styles.module.scss"

const FooterIcons = () => {
  const settingsContext = useContext(SettingsContext)

  const handleClickFacebook = () => {
    ReactGA.event("facebook")
  }

  const handleClickInstagram = () => {
    ReactGA.event("instagram")
  }

  const handleClickPhone = () => {
    ReactGA.event("phone_number", {
      position: "footer",
    })
  }

  return (
    settingsContext &&
    settingsContext?.settings && (
      <div>
        <div>
          {settingsContext.settings.headerEmail && (
            <Link
              href={`mailto:${settingsContext.settings.headerEmail}`}
              className={styles.icons__main}
            >
              <EmailIcon />
              <span className={styles.icons__text}>
                {settingsContext.settings.headerEmail}
              </span>
            </Link>
          )}
        </div>
        <div>
          {settingsContext.settings.mobilePhone &&
            settingsContext.settings.mobilePhone.length > 0 &&
            settingsContext.settings.mobilePhone.map((phone, index) => (
              <Link
                href={`tel:${phone.replace(/[()\s]/g, "")}`}
                key={index}
                className={styles.icons__main}
                onClick={handleClickPhone}
              >
                <PhoneIcon />
                <span className={styles.icons__text}>
                  {settingsContext.settings.mobilePhone}
                </span>
              </Link>
            ))}
        </div>
        <div>
          {settingsContext.settings.instagramLink && (
            <Link
              rel="nofollow"
              href={settingsContext.settings.instagramLink}
              className={styles.icons__second}
              aria-label="Our Instagram"
              target="_blank"
              onClick={handleClickInstagram}
            >
              <InstagramIcon />
            </Link>
          )}
          {settingsContext.settings.facebookLink && (
            <Link
              rel="nofollow"
              href={settingsContext.settings.facebookLink}
              className={styles.icons__second}
              aria-label="Our Facebook"
              target="_blank"
              onClick={handleClickFacebook}
            >
              <FacebookIcon />
            </Link>
          )}
        </div>
        <div className={styles.links}>
          {settingsContext.settings.footerLinks?.map(({ fields }) => (
            <Link
              href={fields.link}
              key={fields.link}
              title={fields.linkTitle || ""}
              className={styles.link}
            >
              {fields.content}
            </Link>
          ))}
        </div>
      </div>
    )
  )
}

export default FooterIcons
