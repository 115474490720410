import "@/styles/variables.css"
import "@/styles/globals.css"

import { useState, useEffect } from "react"
import ReactGA from "react-ga4"
import Router from "next/router"
import type { AppProps } from "next/app"
import { ThemeProvider } from "next-themes"
import { Montserrat } from "next/font/google"
import Head from "next/head"
import parse from "html-react-parser"
import Header from "@/components/Header/Header"
import Footer from "@/components/Footer/Footer"
import { SettingsProvider } from "@/context/settings"
import { useHistoryManager, HistoryManagerProvider } from "@/context/history"
import { PageLoading } from "@/components/PageLoading/PageLoading"

const montserrat = Montserrat({ subsets: ["latin"] })

export default function App({ Component, pageProps }: AppProps) {
  const historyManager = useHistoryManager()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    Router.events.on("routeChangeStart", (_, { shallow }) => {
      if (!shallow) {
        setIsLoading(true)
      }
    })
    Router.events.on("routeChangeComplete", (_, { shallow }) => {
      if (!shallow) {
        setIsLoading(false)
      }
    })
    Router.events.on("routeChangeError", () => {
      setIsLoading(false)
    })
  }, [])

  ReactGA.initialize("GTM-NS9D9N3")

  return (
    <>
      <style jsx global>{`
        :root {
          --font-montserrat: ${montserrat.style.fontFamily};
        }
        html {
          font-family: ${montserrat.style.fontFamily};
        }
      `}</style>
      <Head>{parse(pageProps.settings.headerScripts)}</Head>
      <ThemeProvider enableColorScheme={false}>
        <SettingsProvider defaultSettings={pageProps.settings}>
          <HistoryManagerProvider value={historyManager}>
            <PageLoading isLoading={isLoading}>
              <Header
                menu={pageProps.settings.menu}
                isLogoScrolled={
                  pageProps?.page?.fields?.sections?.[0]?.sys.contentType.sys
                    .id === "sliderWithForm" ||
                  pageProps?.car?.fields?.sections?.[0]?.sys.contentType.sys
                    .id === "sliderWithForm"
                }
              />
              <Component {...pageProps} />
              <Footer />
            </PageLoading>
          </HistoryManagerProvider>
        </SettingsProvider>
      </ThemeProvider>
      {parse(pageProps.settings.googleTagScript)}
    </>
  )
}
