import { ButtonHTMLAttributes, FunctionComponent } from "react"
import { useTheme } from "next-themes"
import cn from "classnames"
import styles from "./styles.module.scss"

interface BurgerMenuProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isOpen: boolean
}

const BurgerMenu: FunctionComponent<BurgerMenuProps> = ({
  isOpen = false,
  className,
  ...props
}) => {
  const { resolvedTheme } = useTheme()

  return (
    <button
      {...props}
      aria-label="Toggle menu"
      className={cn(styles.menu, className, isOpen && styles["menu--open"])}
    >
      <div
        className={cn(
          styles.menu__line,
          styles[`menu__line--${resolvedTheme}`]
        )}
      />
      <div
        className={cn(
          styles.menu__line,
          styles[`menu__line--${resolvedTheme}`]
        )}
      />
      <div
        className={cn(
          styles.menu__line,
          styles[`menu__line--${resolvedTheme}`]
        )}
      />
    </button>
  )
}

export default BurgerMenu
