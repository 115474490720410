import { PropsWithChildren, Children } from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

interface SectionProps extends PropsWithChildren {
  className?: string
  id?: string
  sectionClassName?: string
}

const Section = ({
  children,
  className,
  id = "",
  sectionClassName,
}: SectionProps) => {
  return (
    <div className={cn(styles.section, className)} id={id}>
      {Children.toArray(children).map((item, index) => (
        <div key={index} className={cn(styles.section__item, sectionClassName)}>
          {item}
        </div>
      ))}
    </div>
  )
}

export default Section
