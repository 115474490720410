import { FunctionComponent, ReactNode } from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

export type TagType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "div"

interface TitleProps {
  children: ReactNode
  className?: string
  variant?: TagType
}

const SectionTitle: FunctionComponent<TitleProps> = ({
  children,
  className,
  variant = "h2",
}) => {
  const Tag = `${variant}` as keyof JSX.IntrinsicElements

  return <Tag className={cn(styles.title, className)}>{children}</Tag>
}

export default SectionTitle
