import { PropsWithChildren } from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

interface PageLoadingProps extends PropsWithChildren {
  isLoading: boolean
}
export const PageLoading = ({ children, isLoading }: PageLoadingProps) => {
  return (
    <>
      <div className={cn(styles.page, isLoading && styles["page--disable"])}>
        <div className={styles.page__loading}>
          <div className={styles["page__loading-item"]} />
          <div className={styles["page__loading-item"]} />
          <div className={styles["page__loading-item"]} />
          <div className={styles["page__loading-item"]} />
        </div>
      </div>
      {children}
    </>
  )
}
