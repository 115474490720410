import { createContext, useEffect, useState } from "react"
import { Settings, SettingsContextType } from "@/types/settings"

export const SettingsContext = createContext<SettingsContextType | null>(null)

export const SettingsProvider: React.FC<{
  children: React.ReactNode
  defaultSettings: Settings
}> = ({ children, defaultSettings }) => {
  const [settings, setSettings] = useState<Settings>(defaultSettings)

  const saveSettings = (settings: Settings) => {
    setSettings(settings)
  }

  return (
    <SettingsContext.Provider value={{ settings, saveSettings }}>
      {children}
    </SettingsContext.Provider>
  )
}
