import {
  ButtonHTMLAttributes,
  FunctionComponent,
  ReactNode,
  AnchorHTMLAttributes,
  forwardRef,
  ForwardedRef,
} from "react"
import cn from "classnames"

import styles from "./styles.module.scss"

export type VariantType = "dark" | "light"
export type TagType = "a" | "button"
export type Type = "primary" | "secondary"

export type ButtonProps = {
  variant?: VariantType
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  as?: TagType
  asType?: Type
} & (
  | ButtonHTMLAttributes<HTMLButtonElement>
  | AnchorHTMLAttributes<HTMLAnchorElement>
)

const Button: FunctionComponent<ButtonProps> = forwardRef(
  (
    {
      children,
      className,
      variant = "light",
      leftIcon,
      rightIcon,
      as = "button",
      asType = "primary",
      ...props
    },
    ref: ForwardedRef<HTMLElement>
  ) => {
    const Tag = `${as}` as React.ElementType

    return (
      <Tag
        className={cn(
          styles.button,
          styles[`button--${variant}`],
          styles[`button--${asType}`],
          className
        )}
        {...props}
        ref={ref}
      >
        {leftIcon && (
          <div className={cn(styles.icon, styles["icon--left"])}>
            {leftIcon}
          </div>
        )}
        {children}
        {rightIcon && (
          <div className={cn(styles.icon, styles["icon--right"])}>
            {rightIcon}
          </div>
        )}
      </Tag>
    )
  }
)

Button.displayName = "Button"

export default Button
