import { forwardRef, ForwardedRef } from "react"
import dynamic from "next/dynamic"
import cn from "classnames"
import { PhoneInputProps } from "react-phone-input-2"

import "react-phone-input-2/lib/style.css"
import styles from "./styles.module.scss"

const PhoneInput = dynamic(() => import("react-phone-input-2"), {
  ssr: false,
})

export type VariantType = "dark" | "light"

export type PhoneInputComponentProps = {
  className?: string
  label?: string
  variant?: VariantType
  id?: string
} & PhoneInputProps

const PhoneInputComponent = forwardRef(
  (
    {
      className,
      label,
      variant = "light",
      id,
      ...props
    }: PhoneInputComponentProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div
        ref={ref}
        className={cn(styles.input, styles[`input--${variant}`], className)}
      >
        {label && (
          <label className={styles.input__label} htmlFor={id}>
            {label}
          </label>
        )}
        <PhoneInput
          {...props}
          inputClass={styles.input__field}
          buttonClass={styles.input__dropdown}
          inputProps={{ id }}
        />
      </div>
    )
  }
)

PhoneInputComponent.displayName = "PhoneInput"

export default PhoneInputComponent
